//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createApproval } from '@/api/modular/fileStream/documentManage'
import { templateList } from '@/api/modular/fileStream/template'
import templateNodes from '@/views/fileStream/documentManage/templateNodes.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import formTable from '../components/formTable'
import minxin, { initWx } from '../components/minxin'
import selectLabelPer from '../components/selectLabelPer.vue'
import selectPerson from '../components/selectPerson'
import baseInfo from './components/baseInfo.vue'
export default {
    components: {
        formTable,
        baseInfo,
        templateNodes,
        OpenData,
        selectPerson,
        selectLabelPer,
    },
    mixins: [initWx, minxin],
    data() {
        return {
            myData: {},
            list: [{ name: '文宣綜合部經理審批', val: [] }],
            csr_list: [],
            files: [],
            templateMessList: [],
            selectTemplateId: '',
            selectTemplate: null,
        }
    },
    mounted() {
        this.get_info(this.$route.query.id)
        this.getTemplateList()
    },
    methods: {
        showSelectDialog(key, index) {
            this.$refs.selectPerson.open({ key, index })
        },
        objectEquail(obj1, obj2) {
            return JSON.stringify(obj1) === JSON.stringify(obj2)
        },
        getDataType({ deptId }) {
            return deptId ? 'departmentName' : 'userName'
        },
        initNodes(tag) {
            const { nodeList } = tag
            const formatNodes = (nodes) => {
                return nodes.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
            }
            this.list = nodeList
                .filter((j) => j.sort !== 100)
                .map((i) => ({ name: i.name, val: formatNodes(i.userList) }))
            this.csr_list = nodeList
                .find((j) => j.sort === 100)
                .userList.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
        },
        templateChange(evt) {
            this.selectTemplateId = evt === -1 ? '' : evt
            const tem = this.templateMessList.find((j) => j.id === evt)
            if (tem) {
                this.selectTemplate = tem
                this.initNodes(tem)
            } else {
                this.list = [{ name: '文宣綜合部經理審批', val: [] }]
                this.csr_list = []
            }
        },
        getTemplateList() {
            templateList({ status: 1 }).then((res) => {
                if (res.code === 200) {
                    this.templateMessList = res.data
                }
            })
        },
        selectTodoMsg({ type, data }) {
            if (type === 'book') {
                this.choosePerson(data.key, data.index)
            } else if (type === 'label') {
                this.$refs.selectLabelPer.open({ ...data })
            } else if (type === 'labelResult') {
                const { result, key, index } = data
                console.log(result, key, index)
                if (key === 'list') {
                    result.forEach((ele) => {
                        if (!this[key][index].val.some((j) => this.objectEquail(j, ele))) {
                            this[key][index].val.push(ele)
                        }
                    })
                } else {
                    result.forEach((element) => {
                        if (!this[key].some((j) => this.objectEquail(j, element))) {
                            this[key].push(element)
                        }
                    })
                }
            }
        },
        addDom() {
            this.list.push({ name: '節點', val: [] })
        },
        editDom() {
            const dom = arguments[0].target.parentElement.getElementsByClassName('input-dom')[0]
            dom.removeAttribute('readOnly')
            dom.classList.remove('input-dom-edit')
        },
        delDom(i) {
            this.list.splice(i, 1)
        },
        addApproval() {
            const arr = []
            if (this.list[0].val.length === 0) {
                this.$message.error('未添加審批人！')
                return
            } else if (this.list.some((j) => !j.val || j.val.length === 0)) {
                this.$message.warning('存在節點沒有選擇人員！')
                return
                // eslint-disable-next-line brace-style
            } else {
                this.list.forEach((el, i) => {
                    const uid = []
                    const departId = []
                    el.val.forEach((el) => {
                        if (el.type === 'departmentName') {
                            departId.push({ departmentId: el.id, corpId: el.corpId })
                        } else if (el.type === 'userName') {
                            uid.push({ userId: el.userId, corpId: el.corpId, status: 0 })
                        }
                    })
                    arr.push({ name: el.name, sort: i, userList: uid, departments: departId })
                })
                // eslint-disable-next-line camelcase
                const csr_uid = []
                const sendDepartId = []
                this.csr_list.forEach((el) => {
                    if (el.type === 'userName') {
                        csr_uid.push({ userId: el.userId, corpId: el.corpId, status: 0 })
                    } else if (el.type === 'departmentName') {
                        sendDepartId.push({ departmentId: el.id, corpId: el.corpId })
                    }
                })
                arr.push({ name: '抄送人', sort: 100, userList: csr_uid, departments: sendDepartId })
            }
            createApproval({
                billId: this.$route.query.id,
                processesList: arr,
                approvalWay: 1,
                templateId: this.selectTemplateId,
            })
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('審批發起成功')
                        this.$router.go(-1)
                    } else {
                        this.$message.warning(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        choosePerson(list, index) {
            let val
            if (list === 'list') {
                val = [...this[list][index].val]
            } else {
                val = [...this[list]]
            }
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
                isList,
            } = getUserListMsg(list, index, val)
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    if (isList) {
                        this.$set(this[list][index], 'val', [...result, ...resPart])
                    } else {
                        this.$set(this, list, [...result, ...resPart])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_info(id) {
            this.form_id = id
            this.getBillDetail(id).then((res) => {
                const { documentFileList, form } = res
                this.files = documentFileList
                this.myData = form
            })
        },
    },
}
